import { render, staticRenderFns } from "./guide-base.vue?vue&type=template&id=8bdbd2e4&scoped=true&"
import script from "./guide-base.vue?vue&type=script&lang=js&"
export * from "./guide-base.vue?vue&type=script&lang=js&"
import style0 from "./guide-base.vue?vue&type=style&index=0&id=8bdbd2e4&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8bdbd2e4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/jenkins/jobs/management-system-frontend/workspace/ant-design-vue-jeecg/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8bdbd2e4')) {
      api.createRecord('8bdbd2e4', component.options)
    } else {
      api.reload('8bdbd2e4', component.options)
    }
    module.hot.accept("./guide-base.vue?vue&type=template&id=8bdbd2e4&scoped=true&", function () {
      api.rerender('8bdbd2e4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/production/details/guide/guide-base.vue"
export default component.exports