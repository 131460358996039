var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "body-layout",
    {
      directives: [
        {
          name: "has",
          rawName: "v-has",
          value: _vm.viewPermissionString,
          expression: "viewPermissionString",
        },
      ],
      attrs: {
        isUseBodyLayoutStyle: _vm.isUseBodyLayoutStyle,
        title: _vm.title,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "actions flex",
          attrs: { slot: "actions" },
          slot: "actions",
        },
        [
          _vm.selectedRowKeys.length
            ? _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: _vm.deletePermissionString,
                      expression: "deletePermissionString",
                    },
                  ],
                  staticClass: "mr-3",
                  attrs: { type: "danger", icon: "delete" },
                  on: { click: _vm.batchDel },
                },
                [_vm._v("删除")]
              )
            : _vm._e(),
          _vm.selectedRowKeys.length
            ? _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: _vm.editPermissionString,
                      expression: "editPermissionString",
                    },
                  ],
                  staticClass: "mr-3",
                  attrs: {
                    type: "primary",
                    disabled: _vm.selectedRowKeys.length >= 2,
                    icon: "cloud",
                  },
                  on: { click: _vm.pSync },
                },
                [_vm._v("同步")]
              )
            : _vm._e(),
          _c(
            "a-button",
            {
              directives: [
                {
                  name: "has",
                  rawName: "v-has",
                  value: _vm.addPermissionString,
                  expression: "addPermissionString",
                },
              ],
              attrs: { type: "primary" },
              on: { click: _vm.openAddForm },
            },
            [_vm._v(_vm._s(_vm.btnTitle || "新建"))]
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          false
            ? _c(
                "div",
                {
                  staticClass: "ant-alert ant-alert-info",
                  staticStyle: { "margin-bottom": "16px" },
                },
                [
                  _c("i", {
                    staticClass: "anticon anticon-info-circle ant-alert-icon",
                  }),
                  _vm._v("已选择"),
                  _c("a", { staticClass: "mx-1 text-bold" }, [
                    _vm._v(_vm._s(_vm.selectedRowKeys.length)),
                  ]),
                  _vm._v("项  \n      "),
                  _c(
                    "a",
                    {
                      staticStyle: { "margin-left": "24px" },
                      on: { click: _vm.onClearSelected },
                    },
                    [_vm._v("清空")]
                  ),
                ]
              )
            : _vm._e(),
          _c("a-table", {
            ref: "table",
            staticStyle: { "border-top": "1px solid rgb(232,232,232)" },
            attrs: {
              size: "middle",
              rowKey: "id",
              columns: _vm.columns.filter(
                (c) =>
                  (c.dataIndex != "status" && c.dataIndex != "action") ||
                  _vm.isProduct
              ),
              dataSource: _vm.dataSource.filter((item) => {
                return _vm.isView(item)
              }),
              customRow: _vm.clickRow,
              pagination: _vm.ipagination,
              loading: _vm.loading,
              rowSelection: _vm.rowSelection,
              scroll: { x: "1200px", y: "calc(100vh - 405px)" },
            },
            on: { change: _vm.handleTableChange },
            scopedSlots: _vm._u([
              {
                key: "classId",
                fn: function (text, record) {
                  return _c(
                    "span",
                    {},
                    _vm._l(_vm.configFormData.classIds, function (item) {
                      return _c("label", { key: item.value }, [
                        item.value === text
                          ? _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.label) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ])
                    }),
                    0
                  )
                },
              },
              {
                key: "typeId",
                fn: function (text, record) {
                  return _c("span", {}, [
                    !_vm.propsFilters.typeId || _vm.isDefaultTypeId
                      ? _c(
                          "div",
                          _vm._l(_vm.configFormData.typeIds, function (item) {
                            return _c("label", { key: item.value }, [
                              item.value === text
                                ? _c("span", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.label) +
                                        "\n            "
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          }),
                          0
                        )
                      : _vm._e(),
                  ])
                },
              },
              {
                key: "status",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _vm._v(
                      "\n        " +
                        _vm._s(record.status ? "已确认" : "未确认") +
                        "\n      "
                    ),
                  ])
                },
              },
              {
                key: "action",
                fn: function (text, record) {
                  return _c("span", {}, [
                    !record.status
                      ? _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "has",
                                rawName: "v-has",
                                value: "production:guide:send",
                                expression: "'production:guide:send'",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.send(record)
                              },
                            },
                          },
                          [_vm._v("确认")]
                        )
                      : _vm._e(),
                  ])
                },
              },
              {
                key: "departmentNames",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _c(
                      "div",
                      _vm._l(record.departments, function (one) {
                        return _c("a-tag", { key: one.departId }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(one.departName) +
                              "\n          "
                          ),
                        ])
                      }),
                      1
                    ),
                  ])
                },
              },
              {
                key: "positionNames",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _c(
                      "div",
                      _vm._l(record.positions, function (one) {
                        return _c("a-tag", { key: one.positionId }, [
                          _vm._v(
                            _vm._s(one.positionName + "-") +
                              _vm._s(one.type == "VIEW" ? "查看" : "编辑")
                          ),
                        ])
                      }),
                      1
                    ),
                  ])
                },
              },
            ]),
          }),
        ],
        1
      ),
      !_vm.isAdd
        ? _c("guide-modal", {
            attrs: {
              open: _vm.drawerShow,
              "cur-row": _vm.curRow,
              isForm: _vm.isNewForm,
              permission: _vm.editPermissionString,
              departmentIds: _vm.departmentIds,
              propsUrl: _vm.url,
              productionId: _vm.productionId,
              platformId: _vm.platformId,
              departmentId: _vm.propsDepartmentId,
              userInfo: _vm.userInfo,
              isAdmin: _vm.isAdmin,
              "props-filters": _vm.propsFilters,
              "is-preview": false,
              isMaskClosable: _vm.isMaskClosable,
              "hide-edit-btn": false,
            },
            on: { closed: _vm.drawerClosed, onConfirm: _vm.onConfirm },
          })
        : _c("guide-modal-table", {
            attrs: {
              open: _vm.drawerShow,
              "cur-row": _vm.curRow,
              isForm: _vm.isNewForm,
              permission: _vm.editPermissionString,
              propsUrl: _vm.url,
              productionId: _vm.productionId,
              platformId: _vm.platformId,
              departmentId: _vm.propsDepartmentId,
              departmentIds: _vm.departmentIds,
              "props-filters": _vm.propsFilters,
              "is-preview": false,
              isMaskClosable: _vm.isMaskClosable,
              userInfo: _vm.userInfo,
              isAdmin: _vm.isAdmin,
              "hide-edit-btn": false,
            },
            on: { closed: _vm.drawerClosed, onConfirm: _vm.onConfirm },
          }),
      _c("sync-modal", {
        ref: "syncModal",
        attrs: {
          url: "/rule/sync",
          selectedRowKeys: _vm.selectedRowKeys,
          selectionRows: _vm.selectionRows,
          idType: "ruleIds",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }